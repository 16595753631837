var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", { attrs: { cols: "2" } }, [
            _c("header", { staticClass: "app-welcome-message" }, [
              _c("h4", [_vm._v("Partij koppeling")]),
            ]),
          ]),
          _setup.props.allowMutation
            ? _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: {
                        dark: "",
                        to: {
                          name: "PartyAddressDetailsAdd",
                          params: {
                            locationReference:
                              _setup.props.locationReference.toString(),
                            addModus: true,
                          },
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", center: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _setup.props.locationReference
        ? _c(_setup.PartyAddressOverviewGrid, {
            attrs: {
              allowMutation: _setup.props.allowMutation,
              locationReference: _setup.props.locationReference,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }