<script lang="ts" setup>
import PartyAddressOverviewGrid from "@/components/location/PartyAddressOverviewGrid.vue";

const props = defineProps({
  allowMutation: {
    type: Boolean,
    default: false,
  },
  locationReference: {
    type: String,
    default: undefined,
  },
});
</script>

<template>
  <v-container fluid pa-0>
    <v-row class="overview-wrapper">
      <v-col cols="2">
        <header class="app-welcome-message">
          <h4>Partij koppeling</h4>
        </header>
      </v-col>
      <v-col v-if="props.allowMutation" cols="1">
        <v-btn dark class="primary" :to="{ name: 'PartyAddressDetailsAdd', params: { locationReference: props.locationReference.toString(), addModus: true } }">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <party-address-overview-grid v-if="props.locationReference" :allowMutation="props.allowMutation" :locationReference="props.locationReference"></party-address-overview-grid>
  </v-container>
</template>
