var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          dense: "",
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.dateFrom`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.dateFrom)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.dateTill`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.dateTill)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.addressDescription`,
              fn: function ({ item }) {
                return [
                  item.addressDescription == "not valid"
                    ? _c("span", [_vm._v("niet geldig")])
                    : _c("span", [_vm._v(_vm._s(item.addressDescription))]),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "PartyAddressDetails",
                          params: { id: item.id.toString(), addModus: false },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-pencil "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            _vm.allowMutation
              ? {
                  key: `item.partyName`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            to: {
                              name: "Party",
                              params: {
                                partyReference: item.partyReference.toString(),
                                id: item.reference,
                                addModus: false,
                              },
                            },
                            icon: "",
                            small: "",
                            plain: "",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-table "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(item.partyName) + " "),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }