var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", { attrs: { cols: "2" } }, [
            _c("header", { staticClass: "app-welcome-message" }, [
              _c("h4", [_vm._v("Meter koppeling")]),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: {
                    dark: "",
                    to: {
                      name: "LocationNewRegisterDetailsAdd",
                      params: {
                        locationReference: _vm.locationReference.toString(),
                        addModus: true,
                      },
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "", center: "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          dense: "",
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "LocationRegisterDetails",
                          params: {
                            locationReference: _vm.locationReference.toString(),
                            id: item.id,
                            addModus: false,
                          },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-pencil "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.register.description`,
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(item.register.description) + " "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "EU-Register",
                          params: {
                            registerId: item.register.id.toString(),
                            addModus: false,
                          },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-link-variant "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.register.isInd`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-layout",
                    { attrs: { wra: "", "justify-center": "" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "ma-0 pa-0",
                        attrs: { disabled: "", "hide-details": "" },
                        model: {
                          value: item.register.isInd,
                          callback: function ($$v) {
                            _vm.$set(item.register, "isInd", $$v)
                          },
                          expression: "item.register.isInd",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.dateFrom`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.dateFrom)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.dateTill`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.dateTill)) + " "
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }