var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    [
      _setup.flowchart
        ? _c(_setup.VueMermaidString, {
            attrs: { value: _setup.flowchart },
            on: { "node-click": _setup.nodeClick },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }