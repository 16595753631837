var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "", "pb-6": "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        _c(
          "h2",
          [
            _vm.locationReference
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      to: {
                        name: "LocationDetails",
                        params: { locationReference: _vm.locationReference },
                      },
                      icon: "",
                      small: "",
                      plain: "",
                    },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v(" mdi-pencil "),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" Overzicht "),
          ],
          1
        ),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _setup.diagramLarge
                ? _c(
                    "v-container",
                    {
                      staticClass: "boxed",
                      class: { noBorders: false },
                      attrs: { fluid: "" },
                    },
                    [
                      _c(
                        "header",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _vm._v(" Diagram "),
                          _c(
                            "button",
                            {
                              staticClass: "d-none d-lg-flex",
                              on: {
                                click: function ($event) {
                                  _setup.diagramLarge = !_setup.diagramLarge
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-collapse")])],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.LocationDiagram, {
                                attrs: {
                                  locationReference: _vm.locationReference,
                                },
                                on: { navigate: _setup.handleNavigateDiagram },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "masonry",
                { attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 } },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "boxed",
                      class: { noBorders: false },
                      attrs: { fluid: "" },
                    },
                    [
                      _c("header", [_vm._v("Algemene gegevens")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("INNAX-ID")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " + _vm._s(_setup.location.reference) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Omschrijving")]),
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.location.descr) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Bouwdatum")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.formatDateUtcD(_setup.location.consDat)
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Renovatiedatum")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.formatDateUtcD(
                                    _setup.location.renovDat
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Gebruikstatus")]),
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.location.usgStat) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [
                              _vm._v("Is dit gebouw een monument?"),
                            ]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.location.isMonumental === true
                                    ? "Ja"
                                    : "Nee"
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Geldig van")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.formatDateUtcD(_setup.location.valFrom)
                                )
                            ),
                          ]),
                          _c("v-col", [_c("label", [_vm._v("Geldig tot")])]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.formatDateUtcD(_setup.location.valTill)
                                )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _setup.location.detailCnt > 1
                        ? _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c("label", [_vm._v("Versies")]),
                              ]),
                              _c("v-col", [
                                _vm._v(" " + _vm._s(_setup.location.detailCnt)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.location.detailCnt > 1
                        ? _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c("label", [_vm._v("Begindatum")]),
                              ]),
                              _c("v-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _setup.formatDateUtcD(
                                        _setup.location.dateFrom
                                      )
                                    )
                                ),
                              ]),
                              _c("v-col", [_c("label", [_vm._v("Einddatum")])]),
                              _c("v-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _setup.formatDateUtcD(
                                        _setup.location.dateTill
                                      )
                                    )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_setup.diagramLarge
                    ? _c(
                        "v-container",
                        {
                          staticClass: "boxed",
                          class: { noBorders: false },
                          attrs: { fluid: "" },
                        },
                        [
                          _c(
                            "header",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _vm._v(" Diagram "),
                              _c(
                                "button",
                                {
                                  staticClass: "d-none d-lg-flex",
                                  on: {
                                    click: function ($event) {
                                      _setup.diagramLarge = !_setup.diagramLarge
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-arrow-expand")])],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(_setup.LocationDiagram, {
                                    attrs: {
                                      locationReference: _vm.locationReference,
                                    },
                                    on: {
                                      navigate: _setup.handleNavigateDiagram,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-container",
                    {
                      staticClass: "boxed",
                      class: { noBorders: false },
                      attrs: { fluid: "" },
                    },
                    [
                      _c("header", [_vm._v("Functies")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Type")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " + _vm._s(_setup.location.typDescr) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Functie")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " + _vm._s(_setup.location.ecmFuncName) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("WEii Bouwbesluit")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(_setup.location.weiiBldDecreeName) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("WEii Klasse")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " + _vm._s(_setup.location.weiiBldCatName) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("NTA8800 Functie")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(_setup.location.nta8800FuncDescr) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("EED")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " + _vm._s(_setup.location.eedTypDescr) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("GBS")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " + _vm._s(_setup.location.gbsTypDescr) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    {
                      staticClass: "boxed",
                      class: { noBorders: false },
                      attrs: { fluid: "" },
                    },
                    [
                      _c("header", [_vm._v("Oppervlakten")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "8", md: "4" } }, [
                            _c("label", [_vm._v("Gebruiksoppervlakte (GO)")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4", md: "2" } }, [
                            _setup.location.go != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("number")(
                                        _setup.location.go,
                                        "0,0",
                                        { thousandsSeparator: "." }
                                      )
                                    ) + " m²"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                          _c("v-col", { attrs: { cols: "8", md: "4" } }, [
                            _c("label", [
                              _vm._v("Netto vloeroppervlakte (NVO)"),
                            ]),
                          ]),
                          _c("v-col", { attrs: { cols: "4", md: "2" } }, [
                            _setup.location.nvo != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("number")(
                                        _setup.location.nvo,
                                        "0,0",
                                        { thousandsSeparator: "." }
                                      )
                                    ) + " m²"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                          _c("v-col", { attrs: { cols: "8", md: "4" } }, [
                            _c("label", [
                              _vm._v("Bruto vloeroppervlakte (BVO)"),
                            ]),
                          ]),
                          _c("v-col", { attrs: { cols: "4", md: "2" } }, [
                            _setup.location.bvo != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("number")(
                                        _setup.location.bvo,
                                        "0,0",
                                        { thousandsSeparator: "." }
                                      )
                                    ) + " m²"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                          _c("v-col", { attrs: { cols: "8", md: "4" } }, [
                            _c("label", [
                              _vm._v("Verhuurbaar vloeroppervlakte (VVO)"),
                            ]),
                          ]),
                          _c("v-col", { attrs: { cols: "4", md: "2" } }, [
                            _setup.location.vvo != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("number")(
                                        _setup.location.vvo,
                                        "0,0",
                                        { thousandsSeparator: "." }
                                      )
                                    ) + " m²"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                          _c("v-col", { attrs: { cols: "8", md: "4" } }, [
                            _c("label", [_vm._v("Dakoppervlakte (DO)")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4", md: "2" } }, [
                            _setup.location.do != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("number")(
                                        _setup.location.do,
                                        "0,0",
                                        { thousandsSeparator: "." }
                                      )
                                    ) + " m²"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    {
                      staticClass: "boxed",
                      class: { noBorders: false },
                      attrs: { fluid: "" },
                    },
                    [
                      _c("header", [_vm._v("Hernieuwbare energie")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [
                              _vm._v("Gebruik van hernieuwbare energie?"),
                            ]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.location.isRenewEnergyUsed === true
                                    ? "Ja"
                                    : "Nee"
                                ) +
                                " "
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Aandeel elektriciteit")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(_setup.location.shareOfRenewElecPerc) +
                                " % "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [_c("label")]),
                          _c("v-col"),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Aandeel energie")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(_setup.location.shareOfRenewEnergyPerc) +
                                " % "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [_c("label")]),
                          _c("v-col"),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Aandeel warmte")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(_setup.location.shareOfRenewHeatPerc) +
                                " % "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    {
                      staticClass: "boxed",
                      class: { noBorders: false },
                      attrs: { fluid: "" },
                    },
                    [
                      _c("header", [_vm._v("Notities")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.location.note) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    {
                      staticClass: "boxed",
                      class: { noBorders: false },
                      attrs: { fluid: "" },
                    },
                    [
                      _c("header", [_vm._v("Aanpassing")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4", md: "3" } }, [
                            _c("label", [_vm._v("Aangemaakt op")]),
                          ]),
                          _c("v-col", { attrs: { cols: "8", md: "3" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.formatDateUtcDT(
                                    _setup.location.createdDate
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "4", md: "1" } }, [
                            _c("label", [_vm._v("door")]),
                          ]),
                          _c("v-col", { attrs: { cols: "8", md: "3" } }, [
                            _vm._v(
                              " " + _vm._s(_setup.location.createdBy) + " "
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "4", md: "3" } }, [
                            _c("label", [_vm._v("Aangepast op")]),
                          ]),
                          _c("v-col", { attrs: { cols: "8", md: "3" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.formatDateUtcDT(_setup.location.modDat)
                                ) +
                                " "
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "4", md: "1" } }, [
                            _c("label", [_vm._v("door")]),
                          ]),
                          _c("v-col", { attrs: { cols: "8", md: "3" } }, [
                            _vm._v(" " + _vm._s(_setup.location.modBy) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4", md: "3" } }, [
                            _c("label", [_vm._v("Omschrijving")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " + _vm._s(_setup.location.modDescr) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(_setup.LocationAddress, {
        staticClass: "mt-3",
        attrs: {
          allowMutation: true,
          locationReference: _vm.locationReference,
          addLink: _setup.addAddressLink,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h4", [_vm._v("Adressen")])]
            },
            proxy: true,
          },
        ]),
      }),
      _c(_setup.LocationAddress, {
        staticClass: "mt-3",
        attrs: {
          allowMutation: true,
          locationReference: _vm.locationReference,
          showChilds: "",
          addLink: _setup.addSublocationLink,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h4", [_vm._v("Sublocatie adressen")])]
            },
            proxy: true,
          },
        ]),
      }),
      _c(_setup.LocationConstruction, {
        attrs: { locationReference: _vm.locationReference },
      }),
      _c(_setup.LocationInstallation, {
        attrs: { locationReference: _vm.locationReference },
      }),
      _c(_setup.LocationRegister, {
        attrs: { locationReference: _vm.locationReference },
      }),
      _c(_setup.EnergyLabel, {
        attrs: { locationReference: _vm.locationReference },
      }),
      _c(_setup.LocationParties, {
        attrs: {
          allowMutation: true,
          locationReference: _vm.locationReference,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }